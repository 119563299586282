/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "../components/Header/header"
import Footer from "../components/Footer/footer"
import HeaderMobile from "./Header/headerMobile"
import CookieConsent, { Cookies } from "react-cookie-consent"


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <HeaderMobile />
        <div className="content">
          <div className="container">
            {children}
          </div>
        </div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Permitir"
          declineButtonText="Negar"
          flipButtons
          enableDeclineButton
          onAccept={() => {
            console.log("user's with us, boys");
            Cookies.set("gatsby-gdpr-google-analytics","true");
            Cookies.set("gatsby-gdpr-google-tagmanager","true");
            Cookies.set("gatsby-gdpr-facebook-pixel","true");
            Cookies.set("gatsby-gdpr-google-ads","true");
          }}
          onDecline={() => {
            console.log("user's not with us, boys");
            Cookies.set("gatsby-gdpr-google-analytics","false");
            Cookies.set("gatsby-gdpr-google-tagmanager","false");
            Cookies.set("gatsby-gdpr-facebook-pixel","false");
            Cookies.set("gatsby-gdpr-google-ads","false");
          }}>
          Este site usa cookies de terceiros para enriquecer sua experiência. Para conferir nossa Política de
          Privacidade, <Link to="/politica-de-privacidade">clique aqui</Link>.
        </CookieConsent>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
